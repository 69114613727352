import React, { createContext } from 'react';

interface FeatureFlags {
  goal_tracking?: boolean;
  forecasting?: boolean;
  mock?: boolean;
}

export const FeatureFlagsContext = createContext<FeatureFlags>({});

const FLAG_STORAGE_PREFIX = 'ff_';
const PREFIX_LENGTH = FLAG_STORAGE_PREFIX.length;

// Change these environment variables in .env or .env.stage to change the default
// in that environment.
const DEFAULT_VALUES: FeatureFlags = {
  goal_tracking: process.env.REACT_APP_FF_GOAL_TRACKING === 'true',
  forecasting: process.env.REACT_APP_FF_FORECASTING === 'true',
  mock: process.env.REACT_APP_FF_MOCK === 'true',
};

export const FeatureFlagsProvider = ({ children }) => {
  // init feature flags to whatever we'd previously stored in session storage
  let featureFlags: FeatureFlags = { ...DEFAULT_VALUES };

  if (window.sessionStorage) {
    featureFlags = Object.keys(window.sessionStorage).reduce(
      (flagsObject, currentKey) => {
        if (currentKey.startsWith(FLAG_STORAGE_PREFIX)) {
          // eslint-disable-next-line no-param-reassign
          flagsObject[currentKey.slice(PREFIX_LENGTH)] =
            window.sessionStorage.getItem(currentKey) === 'true';
        }
        return flagsObject;
      },
      featureFlags,
    );
  }

  // override/set any feature flags found in URL parameter, identified by starting with 'ff_'
  const urlParams = new URLSearchParams(window.location.search);

  urlParams.forEach((value, key) => {
    if (key.startsWith(FLAG_STORAGE_PREFIX)) {
      const flagValue = value === 'true';

      featureFlags[key.slice(PREFIX_LENGTH)] = flagValue;
      if (window.sessionStorage) {
        window.sessionStorage.setItem(key, JSON.stringify(flagValue));
      }
    }
  });

  // return them in a context that can be consumed anywhere in the app
  return (
    <FeatureFlagsContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
